import {AppContainer} from "./AppContainer";
import './App.css';
import "./i18n"

function App() {
    return (<div className="App">
        <AppContainer/>
    </div>);
}

export default App;
